<template>
  <v-row class="my-5" v-if="reservation != null">
    <div class="main-bg-img"></div>
    <v-col cols="12" sm="10" md="8" lg="6" class="mx-auto text-center">
      <v-card>
        <v-col cols="12">

          <v-card-title class="green--text">
            <v-icon x-large color="green">mdi-thumb-up</v-icon><br>Votre réservation a été enregistrée
          </v-card-title>
          <br>
          <v-card-subtitle class="green--text">
            Merci de procéder au paiement pour valider votre réservation
          </v-card-subtitle>

          <v-divider/>
          <br>
          {{ reservation.totalPrice }}€ à régler
          <br>
          <br>

          <v-divider/>

          <br>
          <v-btn class="my-2 mx-auto" large color="blue" dark @click="startCBPayment()">
            <v-icon class="mr-1">mdi-credit-card-fast</v-icon> 
            <b>PAYER par carte bancaire</b>
          </v-btn>

          <br>

          <small>
            <v-icon small class="mr-1">mdi-lock</v-icon>
            Paiement via la plateforme sécurisée 
            <a href="https://stripe.com/fr" target="_blank"><b>Stripe</b></a>
          </small>
          
          <br>
          <br>

          <v-alert color="red" outlined v-if="formError" class="mb-5">
            Une erreur est survenue après votre paiement
            <v-btn class="mt-4 mx-auto" block dark to="/contact" color="red">
              <v-icon small class="mr-1">mdi-email</v-icon> Envoyer un message à Nanoug
            </v-btn>
          </v-alert>

          <v-divider/>

          <br>

          <v-btn class="my-2 mx-auto" @click="showMsg=!showMsg">
            <b>Je ne peux pas payer en ligne</b>
          </v-btn>
          <br><br>
          <v-card outlined v-if="showMsg">
            <v-col cols="12" class="text-left">
              <p style="font-size:20px;" class=" font-flower">
              Envoyez moi un message via le formulaire de contact pour m'expliquer votre situation.
              J'accèpte aussi les paiements en espèce en cas de remise en main propre, 
              ou bien les virements bancaires.
              </p>
              <v-btn class="mt-4 mx-auto" block outlined to="/contact">
                <v-icon small class="mr-1">mdi-email</v-icon> Envoyer un message à Nanoug
              </v-btn>
            </v-col>
          </v-card>

        </v-col>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import core from '../../../plugins/core.js'
import axios from "axios"
//import router from "@/router/router"

export default {
  name: "App",
  components: {
  },
  data: function () {
      return {
          reservation: null,
          showMsg: false,
          formError: false
      };
  },
  mounted: async function(){
    let res = await axios.post('/data/query-entities', {
                      entityType: "reservation",
                      query: { _id: this.$route.params.resaId  }
                    })
    if(res.data.error == false){
      this.reservation = res.data.entities[0]
    }

  },
  methods: {
      async startCBPayment(){
        console.log('/pay/reservation/all' + this.$route.params.resaId)
        const { data } = await axios.post('/pay/reservation/all/' + this.$route.params.resaId)

        if(data.error == false){
          console.log('Success after /pay/reservation/' + this.$route.params.resaId, data)
          window.location.href = data.sessionUrl
        }else{
          console.log('Error after /pay/reservation/' + this.$route.params.resaId)
          //this.formError = true
        }

      },
      baseUrl(){ return core.baseUrl() }
  },
  computed:{
    
  }
};
</script>
